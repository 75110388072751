import React, { FC, useState } from 'react'
import { ListItem, Button } from '@material-ui/core'
import useStore from 'store'
import { Row, Space, theme } from 'ui'
import Text from 'ui/Text/Text'
import { extractMessagePayload } from 'utils/messageUtils'
import type { Message } from 'utils/models'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import CustomPopover from './CustomPopover'
import LinkText from 'components/Messages/LinkText'
import PdfPreview from '../PdfPreview'
import Progress from '../Progress'
import MessageStatus from '../MessageStatus'
import { StatusType } from 'utils/types'
import api from 'service/api'
import { CommunityResponse, MessageDetails } from 'store/messages.store'
import { useCountUp } from 'react-countup'
import { Spinner } from 'ui'
import { FileCopy } from '@material-ui/icons';

const StyledButton = styled(Button)`
  color: rgb(0, 150, 130);
  min-width: 10px;
  margin: 0px;
  padding: 0px;
`

const TooltipButton = styled.div`
    border-radius: 50%;
    background: #3389fa;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    `
const TooltipButtonLoading = styled.div`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: mouse;
  `    
const MessageContent = styled.div`
  display:flex;
  flex-direction: column;
  padding: 0.5rem;
  border:1px solid ${theme.color.whiteBackground + 80};
  border-radius: 10px;
  max-width:100%;
  min-width:100%;
`
const MessageContentContainer = styled.div<{ isTypeMedia: boolean, fullWidth?: boolean }>`
@media (max-width: 768px) {
  margin-left:1px;
  max-width: 100%;
  width:100%;
}
  background-color: ${theme.color.whiteBackground};
  border-radius: 10px;
  display:flex;
  flex-direction: column;
  flex-wrap:wrap;
  /* max-width:${p => p.fullWidth ? '100%' : p.isTypeMedia ? '50%' : '50%'}; */
  flex-grow:${p => p.fullWidth ? 1 : p.isTypeMedia ? 1 : 0};
  box-shadow: 0 2px 8px 0 ${theme.color.shadow};
  margin-left:20px;
  /* width:50%; */
  flex:1;
  position:relative;
`
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const MessageSide = styled.div`
  display:flex;
  flex-direction:column;
  align-self:stretch;
  justify-content:space-between;
  flex:1;
`
const MessageHeader = styled(Row)`
  padding:12px;
  align-items:center;
  margin:-8px;
  border-bottom:1px solid ${theme.color.blandGray};
`
const Header = styled(Row)`
@media (max-width: 768px) {
  flex:10;
}
flex:2; 
align-items: center;
`
const MessageFooter = styled(Row)`
`
const GraphSide = styled.div`
  display:flex;
  flex-direction:column;
`
const GraphDescription = styled.div`
`
const Circle = styled.div<{ color: string, size: number }>`
  background-color:${p => p.color || 'red'};
  border-radius:50%;
  height:${p => p.size}rem;
  width:${p => p.size}rem;
`
const StyledLinkText = styled.div`
  &>p{
    white-space: pre-wrap;
  }
`
const StyledImage = styled.img`
z-index:10px;
  width:100%;
  /* max-width:500px; */
  height: 100%;
  min-height:300px;
  max-height:300px;
  object-fit:cover;
  :hover {
    cursor: pointer;
  }
`

//here for performance reasons, ask sergey for explaination
const PopoverContainer: React.FC<{ message: Message, responses: CommunityResponse[] }> = observer(({ message, responses = [] }) => {
  const { messages } = useStore()
  const isOpen = messages.activeTooltip === message.id
  return (
    <CustomPopover message={message} open={isOpen} responses={responses} />
  )
})
interface MessageItemProps {
  message: Message
  disabledActions?: boolean
  fullWidth?: boolean
  detailsMode?: boolean
  scheduleButtonColor?: string
  publishMessage?: () => void
  onScheduleEditPressed?: () => void
}

const MessageItem: FC<MessageItemProps> = ({ message, fullWidth }) => {
  const { messages, user: userStore } = useStore()
  const [showStat, setShowStat] = useState(false)
  const [showStatVal, setShowStatVal] = useState(0)
  const { t } = useTranslation('messagesTable')
  const extractedPayload = extractMessagePayload(message)
  const handleClick = (event: any) => {
    messages.setActiveTooltip(message.id)
  };

  const handleClose = () => {
    messages.setActiveTooltip(null)
  };

  const copyText = () => {
    navigator.clipboard.writeText(extractedPayload.text)
  }

  const [responses, setResponses] = React.useState<CommunityResponse[]>([])
  const [total, setTotal] = React.useState(message.total || 1)
  const [received, setReceived] = React.useState(message.received)
  
  const { countUp: totalCountUp, update: totalUpdate } = useCountUp({
    start: message.total || 1,
    end: message.total,
    delay: 0,
    duration: 2,
  });

  const { countUp: receivedCountUp, update: receivedUpdate } = useCountUp({
    start: message.received,
    end: message.received,
    delay: 0,
    duration: 2,
  });

  React.useEffect(() => {
    if ((Date.now() - message.created.getTime()) > (1000 * 60)) {
      setShowStat(true)
      return
    }

    const checkOneMinPassed = () => {
      const timePassed = Date.now() - message.created.getTime();

      if (timePassed > (1000 * 60)) {
        setShowStat(true)
        return
      }

      const newStartProgressVal = Math.floor(60 - (timePassed/1000)) 
      if (newStartProgressVal <= 1) {
        setShowStat(true)
        return
      }

      setShowStat(false)
      setShowStatVal(newStartProgressVal)

      setTimeout(() => {
        checkOneMinPassed()
      }, 1000)
    } 

    checkOneMinPassed();
    
  }, [])

  //interval to check if message details were updated (total, received)
  React.useEffect(() => {
    if (message.id === 0) {
      return;
    }

    if ((Date.now() - message.created.getTime()) > (1000 * 60)) {
      setShowStat(true)
    }

    // if message was created less than 5 min ago, activate a set interval that runs for 1 min every 10 seconds
    if ((Date.now() - message.created.getTime()) < (1000 * 60)) {
      const refreshMessageInfo = () => {
        Promise
          .all([api.getMessageDetails(message.id), userStore.initInfo()])
          .then(([messageDetailsRes, info]) => {
            if (messageDetailsRes.success) {
              const msgDetails = messageDetailsRes.data as MessageDetails[]
              setResponses(msgDetails)

              if (msgDetails.length) {
                const totals = msgDetails.reduce((a, b) => {
                  a.total += b.total
                  a.received += b.received
                  return a
                }, { total: 0, received: 0 })
    
                setTotal(totals.total)
                setReceived(totals.received)
                totalUpdate(totals.total)
                receivedUpdate(totals.received)
              }
            }
          });   
      }

      const doRefresh = (timer) => {
        if (timer > 60000) {
          return 
        }

        refreshMessageInfo()
        setTimeout(() => doRefresh(timer + 15000), timer + 15000)
      }
      
      doRefresh(15000)  
    }

  }, [message.id])

  //incase updated by a different method.
  React.useEffect(() => {
    setTotal(message.total)
    setReceived(message.received)
    totalUpdate(message.total)
    receivedUpdate(message.received)
  }, [message.received, message.total])


  const MessageListItem = styled(ListItem)`
    @media (max-width: 768px) {
      flex:12;
    }
    ${userStore.userListTableView ? '' : 'flex:2;' }    
    position:relative;
    word-break: break-word;
    // TODO: uncomment to align based on message ownership
    justify-content:flex-start;
  `
  return (
    <Row>
      <MessageListItem disableGutters button={false} >
        <MessageContentContainer
          fullWidth={fullWidth}
          isTypeMedia={extractedPayload.mediaType === 'PDF' || extractedPayload.mediaType === 'IMAGE'}
        >
          <MessageContent>
            <Row>
              <MessageSide>
                <div>
                  <MessageHeader justify='stretch'>
                    <Text style={{ flex: 3 }} weight='bold'>{message.labels?.[0] || ''}</Text>
                    {(message.status && message.status != StatusType.SENT) ? <MessageStatus owner={true} status={message.status} message={message} /> : (

                      <Header>
                        <StyledButton onClick={copyText}><FileCopy/></StyledButton>
                        <Space width={1} />
                        <Text textcolor={theme.color.darkGreen} weight='bold'>קיבלו</Text>
                        <Space width={1} />
                        <Text >{total}/</Text>
                        <Text textcolor={theme.color.darkGreen} weight='bold'>{receivedCountUp}</Text>
                        <Space width={1} />
                        <Progress denumerator={Number(total)} numerator={Number(received)} />
                        <Space width={1} />
                        <Text textcolor={theme.color.darkGray} weight='bold'>{Number(totalCountUp) > 0 ? ((Number(receivedCountUp) / Number(totalCountUp)) * 100).toFixed(2) : 0}%</Text>
                        <Space width={1} />
                        {showStat && (
                           <TooltipButton onClick={(e) => {
                            userStore.openUserListTable(false, '');
                            handleClick(e)
                          }}>
                            <Text style={{ lineHeight: 1 }} weight='bold' size='lg'>i</Text>
                          </TooltipButton>
                        )}

                        {!showStat && (
                          <TooltipButtonLoading>
                            <Spinner 
                              enableText={true} 
                              textColor='#3f51b5' 
                              top={25} 
                              textSize={10} 
                              text={`${showStatVal}`} 
                              size={30} 
                            />
                          </TooltipButtonLoading>
                        )}
                       
                      </Header>
                    )}
                    {/* <Space width={1} />
                    <Text textcolor={theme.color.blandGray}>|</Text>
                    <Space width={1} />
                    <Text textcolor={theme.color.blandGray}>נשלח: {format(userMessage.message.created, 'HH:mm')}</Text> */}
                  </MessageHeader>
                  <Space height={1} />
                  <div>
                    {/* {extractedPayload.text} */}
                    <StyledLinkText>
                      <LinkText messageText={extractedPayload.text} />
                    </StyledLinkText>
                    {extractedPayload?.fileUrl && extractedPayload.mediaType === 'IMAGE' && (
                      <StyledImage

                        onClick={() => messages.openViewerModal('image', extractedPayload.fileUrl)}
                        alt={extractedPayload.fileUrl}
                        src={extractedPayload.fileUrl} />
                    )}
                    {extractedPayload?.fileUrl && extractedPayload.mediaType === 'PDF' && (
                      <PdfPreview file={extractedPayload.fileUrl} />
                    )}
                  </div>
                </div>
                {/* <Space height={1} /> */}
                <MessageFooter justify='end'>
                  <Text textcolor={theme.color.blandGray}>{t`messageDetails:sent`}: {format(message.created, 'HH:mm')}</Text>
                  <Space width={1} />
                  <Text textcolor={theme.color.blandGray}>|</Text>
                  <Space width={1} />
                  <Text textcolor={theme.color.blandGray}>{t`expiry`}:</Text>
                  <Space width={0.5} />
                  <Text textcolor={theme.color.blandGray} style={{ direction: 'ltr' }}>{format(message.expiry || new Date(), 'dd / MM / yy')}</Text>
                </MessageFooter>
              </MessageSide>
            </Row>
          </MessageContent>

        </MessageContentContainer>
      </MessageListItem>
      <div style={{ flex: 1, alignSelf: 'flex-start', backgroundColor: 'white', margin: 8, position: 'relative' }}>
        {/* <CustomPopover userMessage={userMessage} handleClose={handleClose} open={activeTooltip} /> */}
        <PopoverContainer message={message} responses={responses} />
      </div>
    </Row>

  )
}

export default observer(MessageItem)
